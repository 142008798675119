import React, { useEffect, useState } from 'react';
import Axios from 'axios'
import {
    Card,
    CardContent,
    CardHeader
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { MDBDataTable } from 'mdbreact';
import EditIcon from '@material-ui/icons/Edit';
import { Button, Form, Label, Modal as Modals, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Alert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Modal, TextField, MenuItem, FormControl, Select, FormHelperText, Input, OutlinedInput, InputLabel, InputAdornment, IconButton, FormLabel } from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop";
import * as CONSTANTS from '../../helpers/UserProfile'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CancelIcon from '@material-ui/icons/Cancel';

import { Redirect } from 'react-router-dom';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const data = require('../../config.json');

const useStyle = theme => ({
    cardContainer: {
        marginTop: '50px'
    },
    cardHeader: {
        textDecorationColor: 'whiter',
        backgroundColor: '#96CED5',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(6, 210, 235, .3)'
    },
    AddButtom: {
        color: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
    }
})

export default function ManageUser() {
    const classes = useStyle();
    const [datatable, setData] = useState([]);
    const [openEdit, setEdit] = useState(false);
    const [openDelete, setDelete] = useState(false);
    const [openAdd, setAdd] = useState(false);
    const [selected, setSelected] = useState({});
    const [redirect, setRedirect] = useState(false);
    useEffect(() => {
        (() => {
            const columns = [
                {
                    label: 'Email Address',
                    field: 'email',
                    width: 250,
                }, {
                    label: 'Name',
                    field: 'name',
                    width: 250,
                }, {
                    label: 'Analysis/Limit',
                    field: 'analysis',
                    width: 250,
                }, {
                    label: 'Edit',
                    field: 'edit',
                    width: 250,
                }, {
                    label: 'Delete',
                    field: 'delete',
                    width: 250,
                },
                {
                    label: 'State',
                    field: 'state',
                    width: 250
                }
            ];
            var rows = [];
            Axios.get(data.URL + "/Api/ListFreelancer", {
                headers: {
                    Authorization: `Bearer ${CONSTANTS.UserSession.token}`
                }
            }).then(res => res.data).then(response => {
                if (response && response.length > 0) {
                    response.map(item => {
                        rows.push({
                            email: item.email,
                            name: item.name,
                            analysis: item.analysis + "/" + item.limit,
                            edit: <EditIcon onClick={() => {
                                setSelected(item);
                                setEdit(true);
                            }} />,
                            delete: <DeleteIcon
                                onClick={() => {
                                    setSelected(item);
                                    setDelete(true);
                                }}
                            />,
                            state: <FiberManualRecordIcon style={{ color: item.emailConfirmed ? '#00FF00' : '#FF0000' }} />

                        })
                    });
                    setData({ columns, rows });
                }

            }).catch(e => e.response.status === 401 ? setRedirect(true) : null)
        })();
    }, [openAdd, openDelete, openEdit])
    if (redirect) {
        CONSTANTS.dropUser();
        return (<Redirect to={'/'} />)
    }
    return (
        <div style={{ marginTop: 140 }}>
            <Card className={classes.cardContainer}>
                <CardHeader className={classes.cardHeader} title={"Manage Users"} action={<AddCircleIcon style={{ fontSize: 40, color: '#ff6600' }} onClick={() => setAdd(true)} />} />
                <CardContent>
                    <MDBDataTable striped hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} noBottomColumns
                        data={datatable} />
                </CardContent>
            </Card>

            {
                openEdit ? <ModalEdit open={openEdit} handleCloseAdd={() => setEdit(false)} item={selected} /> : null
            }
            {
                openAdd ? <ModelAdd open={openAdd} handleCloseAdd={() => setAdd(false)} /> : null
            }
            {
                openDelete ? <ModalDelete open={openDelete} handleCloseAdd={() => setDelete(false)} item={selected} /> : null
            }


        </div>
    )
}

const ModalDelete = (props) => (
    <Modals isOpen={props.open} toggle={props.handleCloseAdd}>
        <ModalHeader toggle={props.handleCloseAdd}>Deleting</ModalHeader>
        <ModalBody>
            Are you sure you want to delete this user?
    </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={() => {
                Axios.post(data.URL + "/Api/DeleteUser?id=" + props.item.id, null, {
                    headers: {
                        Authorization: `Bearer ${CONSTANTS.UserSession.token}`
                    }
                }).then(res => props.handleCloseAdd())
            }}>Delete</Button>{' '}
            <Button color="secondary" onClick={props.handleCloseAdd}>Cancel</Button>
        </ModalFooter>
    </Modals>
)

const ModalEdit = (props) => {
    console.log(props)
    const [email, setEmail] = useState(props.item.email);
    const [password, setPassword] = useState(props.item.password);
    const [name, setName] = useState(props.item.name);
    const [usertype, setUserType] = useState(props.item.userType);
    const [limit, setLimit] = useState(props.item.limit);
    const [analysis, setAnalysis] = useState(props.item.analysis);

    const [passwordValidation, setPasswordValidation] = useState(props.item.password)
    const [showPassword, setTypePassword] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null)
    return (
        <Modal
            open={props.open} onClose={props.handleCloseAdd}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Form style={{
                backgroundColor: '#ffffff',
                margin: 20,
                borderRadius: 15,
                width: '70%',
                height: '70%',
                display: 'grid',
                justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column'
            }}>
                <CancelIcon onClick={props.handleCloseAdd} style={{
                    position: 'relative',
                    left: 650
                }} />
                <TextField
                    id="email"
                    label={"Email*"}
                    contentEditable={false}
                    value={email}
                    autoComplete="current-password"
                />
                <FormControl>
                    <InputLabel htmlFor="password">Password*</InputLabel>
                    <Input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setTypePassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                    <FormHelperText>-Have at least one capital letter - Have at least one number - Not the same as the name - Be at least 8 characters</FormHelperText>
                </FormControl>
                <FormControl>
                    <InputLabel htmlFor="password">Confirm Password*</InputLabel>
                    <Input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={passwordValidation}
                        onChange={(e) => setPasswordValidation(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setTypePassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
                <TextField
                    id="name"
                    label={"Name*"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <FormControl>
                    <InputLabel>User type* </InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="us"
                        value={usertype}
                        displayEmpty
                        onChange={e => {
                            setUserType(e.target.value)
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={false}>Freelancer</MenuItem>
                        <MenuItem value={true}>Administrator</MenuItem>
                    </Select>
                </FormControl>

                {
                    !usertype ? <TextField
                        id="limit"
                        label={"Limit*"}
                        type={"number"}
                        value={limit}
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={(e) => {
                            setLimit(e.target.value);
                            console.log(e.target.value);
                            if (parseInt(e.target.value) - analysis < 0) {
                                setAnalysis(0);
                            }
                        }
                        }
                    /> : null
                }
                <Label>*Obligatory fields</Label>
                <Button disabled={disabled} style={{ backgroundColor: '#ff9933', color: 'white' }}
                    onClick={() => {
                        if (password !== passwordValidation) {
                            setAlertMessage('Password does not match')
                        } else {
                            let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
                            let emailcheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                            console.log(password.match(passw))
                            if (passw.test(password) && password.length >= 8 || password === props.item.password && passwordValidation === props.item.password) {
                                if (email.match(emailcheck)) {
                                    setAlertMessage(null)
                                    setDisabled(true)
                                    Axios.post(data.URL + "/Api/UpdateFreelancer", {
                                        email: email,
                                        password: password,
                                        userType: usertype,
                                        emailConfirmed: props.item.emailConfirmed,
                                        analysis: analysis,
                                        name: name,
                                        id: props.item.id,
                                        limit: parseInt(limit)

                                    }, {
                                        headers: {
                                            Authorization: `Bearer ${CONSTANTS.UserSession.token}`
                                        },
                                        params: {
                                            passwordChange: (password !== props.item.password)
                                        }
                                    }).then(res => {
                                        if (res.status !== 204) {
                                            setAlertMessage("Error in updating User")
                                            setDisabled(false)
                                        } else {
                                            props.handleCloseAdd();
                                        }
                                    }).catch(e => {
                                        if (e.response.status === 304) {
                                            setAlertMessage("This Email already Exist"); setDisabled(false)
                                        }

                                        if (e.response.status === 400) {
                                            setAlertMessage("An Error as Occured please retry"); setDisabled(false)
                                        }
                                    }
                                    );
                                } else {
                                    setAlertMessage('Please provide valid Email')
                                }
                            } else {
                                setAlertMessage("The passwords does not respect the conditions")
                            }
                        }

                    }}
                >Edit</Button>
                {
                    alertMessage ? <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {alertMessage}<strong>Please Provide valid Information</strong>
                    </Alert> : null
                }
            </Form>
        </Modal>

    )


}

const ModelAdd = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [usertype, setUserType] = useState(false);
    const [limit, setLimit] = useState(0);

    const [passwordValidation, setPasswordValidation] = useState('')
    const [showPassword, setTypePassword] = useState(false);

    const [alertMessage, setAlertMessage] = useState(null);

    const [disabled, setDisabled] = useState(false);
    return (
        <Modal
            open={props.open} onClose={props.handleCloseAdd}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Form style={{
                backgroundColor: '#ffffff',
                margin: 20,
                borderRadius: 15,
                width: '70%',
                height: '70%',
                display: 'grid',
                justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column'
            }}>
                <CancelIcon onClick={props.handleCloseAdd} style={{
                    position: 'relative',
                    left: 650
                }} />
                <TextField
                    id="email"
                    label={"Email*"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="current-password"
                />
                <FormControl >
                    <InputLabel htmlFor="password">Password*</InputLabel>
                    <Input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setTypePassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />

                    <FormHelperText>-Have at least one capital letter - Have at least one number - Not the same as the name - Be at least 8 characters</FormHelperText>
                </FormControl>
                <FormControl >
                    <InputLabel htmlFor="password">Confirm Password*</InputLabel>
                    <Input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={passwordValidation}
                        onChange={(e) => setPasswordValidation(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setTypePassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
                <TextField
                    id="name"
                    label={"Name*"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <FormControl>
                    <InputLabel>User type* </InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="us"
                        value={usertype}
                        displayEmpty
                        onChange={e => {
                            setUserType(e.target.value)
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={false}>Freelancer</MenuItem>
                        <MenuItem value={true}>Administrator</MenuItem>
                    </Select>
                </FormControl>
                {
                    !usertype ? <TextField
                        id="limit"
                        label={"Limit*"}
                        type={"number"}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={limit}
                        onChange={(e) => setLimit(e.target.value)}
                    /> : null
                }

                <Label>*Obligatory fields</Label>
                <Button disabled={disabled} style={{ backgroundColor: '#ff9933', color: 'white' }}
                    onClick={(e) => {

                        if (password !== passwordValidation) {
                            setAlertMessage('Password does not match')
                        } else {
                            let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
                            let emailcheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                            console.log(password.match(passw))
                            if (passw.test(password) && password.length >= 8) {
                                if (email.match(emailcheck)) {
                                    setAlertMessage(null)
                                    console.log({
                                        email: email,
                                        password: password,
                                        userType: usertype,
                                        name: name,
                                        limit: parseInt(limit),
                                        emailConfirmed: true
                                    });
                                    setDisabled(true)
                                    Axios.post(data.URL + "/Api/CreateAccount", {
                                        email: email,
                                        password: password,
                                        userType: usertype,
                                        name: name,
                                        limit: parseInt(limit),
                                        emailConfirmed: true
                                    }, {
                                        headers: {
                                            Authorization: `Bearer ${CONSTANTS.UserSession.token}`
                                        }
                                    }).then(res => {
                                        if (res.status !== 204) {
                                            setAlertMessage("Error in updating User")
                                            setDisabled(false);
                                        } else {
                                            props.handleCloseAdd();
                                        }
                                    }).catch(e => {
                                        if (e.response.status === 304) {
                                            setAlertMessage("This Email already Exist"); setDisabled(false)
                                        }
                                        if (e.response.status === 400) {
                                            setAlertMessage("An Error as Occured please retry"); setDisabled(false)
                                        }
                                    });
                                } else {
                                    setAlertMessage('Please provide valid Email')
                                }
                            } else {
                                setAlertMessage("The passwords does not respect the conditions")
                            }
                        }

                    }}
                >Add</Button>
                {
                    alertMessage ? <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {alertMessage}  <strong>Please Provide valid Information</strong>
                    </Alert> : null
                }
            </Form>
        </Modal>

    )

}