import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './Layout';
import Analyzer from './Analyzer';   
export default class FreelancerMainMenu extends React.Component {

    render() {
        return (
            <Layout>
                <Route path='/Freelancer' component={Analyzer} />
            </Layout>
        )
      
    }

}