import  Log  from './Log';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import Analyzer from './Analyzer';
import Layout from './Layout';
import ManageUser from './ManageUser';

export default class MainMenu extends React.Component {

    render() {
        return (
            <Layout>
                        <Route path='/Admin/Analyzer' component={Analyzer} />
                        <Route path='/Admin/ManageUser' component={ManageUser} />
                        <Route path='/Admin/Logs' component={Log} />
            </Layout>
        )
    }

}