import { Button } from '@material-ui/core';
import { Label } from 'reactstrap'
import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import { FormControl, OutlinedInput, InputLabel, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
const data = require('../config.json')

export default function PasswordRecover() {

    const [redirect, setRedirect] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordValidation, setPasswordValidation] = useState('')
    const [showPassword, setTypePassword] = useState(false);
    const [token, setToken] = useState('');
    const [label, setLabel] = useState(null);
    useEffect(() => setToken(new URLSearchParams(window.location.search).get('confirmation_token')), []);

    console.log(token)

    if (!redirect) {
        return (
            <div style={{ display: 'flex', backgroundImage: `url(${data.URL + '/Images/mainbackground.jpg'})`, width: window.innerWidth, height: window.innerHeight, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', width: '30%', height: '70%', justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: 'rgba(240, 245, 245,0.6)', flexDirection: 'column', borderRadius: 25, borderColor: 'rgba(195, 213, 213,0.9)', borderWidth: 1 }}>
                    <img src={data.URL + '/Images/logo.png'} height={300} width={"100%"} />
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="password">Password*</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setTypePassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="password">Confirm Password*</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={passwordValidation}
                            onChange={(e) => setPasswordValidation(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setTypePassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                        />
                    </FormControl>
                    {
                        label ? <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {label} <strong>Please Provide valid Information</strong>
                        </Alert> : null
                    }
                    <Button color={'primary'} onClick={() => {
                        let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
                        if (password !== passwordValidation) {
                            setLabel("Password don't match")
                        } else {
                            if (passw.test(password) && password.length >= 8) {
                                Axios.post(data.URL + "/Api/UpdateUserPassword?password=" + password + "&token=" + token).then(res => setRedirect(true)).catch(e => e.response.status === 404 ? setLabel("URL Not Valid") : null)
                            } else {
                                setLabel("Password must have these conditions : - Have at least on capital letter \n - Have at least on number \n- Not the same as the name\n- Be at least 8 characters")
                            }
                        }

                    }}>Update</Button>
                </div>
            </div>

        )
    } else {
        return (
            <Redirect to="/" />
        )
    }
}