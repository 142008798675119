import { Button } from '@material-ui/core';
import { Label } from 'reactstrap'
import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom';

const data = require('../config.json')



export default function Confirmation() {
    const [redirect, setRedirect] = useState(false);

    if (!redirect) {
        return (
            <div style={{ display: 'flex', backgroundImage: `url(${data.URL + '/Images/mainbackground.jpg'})`, width: window.innerWidth, height: window.innerHeight, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', width: '30%', height: '70%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(240, 245, 245,0.6)', flexDirection: 'column', borderRadius: 25, borderColor: 'rgba(195, 213, 213,0.9)', borderWidth: 1 }}>
                    <img src={data.URL + '/Images/logo.png'} height={300} width={"100%"} />
                    <Label>Your account has been activated.</Label>
                    <Button color={'primary'} onClick={() => setRedirect(true)}>Go to main menu</Button>
                </div>
            </div>

        )
    } else {
        return (
            <Redirect to="/" />
        )
    }
}