import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import './NavMenu.css';
import { Button } from '@material-ui/core';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import * as CONSTANTS from '../../helpers/UserProfile'
const data = require('../../config.json');


export default class NavMenu extends Component {
  static displayName = NavMenu.name;
  constructor(props) {
    super(props); 
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      User: CONSTANTS.UserSession ? CONSTANTS.UserSession.user : null
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    console.log(CONSTANTS.UserSession)
    const { User } = this.state; 
    if (User && User.userType === false) {
      return (
        <header>
          <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
            <Container>
              <NavbarBrand tag={Link} to="/" style={{ flexDirection: 'row', display: 'contents' }}><img src={data.URL + '/Images/NavLogo.png'} height={120} width={400} style={{ right: 60 ,marginRight : 40,position : 'relative'}} /> <h3>Welcome {User.name}</h3></NavbarBrand>
              <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
              <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                <ul className="navbar-nav flex-grow"> 
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/">
                      <ExitToAppIcon fontSize={'large'} onClick={() => {
                           this.setState({ User: null }); CONSTANTS.dropUser(); 
                      }}/> 
                      </NavLink>
                    </NavItem>
                </ul>
              </Collapse>
            </Container>
          </Navbar>
        </header>
      );
    } else {
      return (
        <Redirect to={"/"} />
      )
    }


  }
}
