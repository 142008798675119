import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Popover, PopoverHeader, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Select, MenuItem, Collapse } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Axios from 'axios';
import { PDFDownloadLink, Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer';
import * as CONSTANTS from '../../helpers/UserProfile'
import { Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import Fade from '@material-ui/core/Fade';


const data = require('../../config.json');

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    underlined: {
        fontSize: 14.1, fontWeight: 'bold', fontStyle: 'normal', textDecoration: 'underline'
    },
    boldText: {
        fontSize: 14.1, fontWeight: 'bold', fontStyle: 'normal', marginTop: 20, marginBottom: 20
    },
    normalText: {
        fontSize: 14.1, fontWeight: 'normal', fontStyle: 'normal'
    },
    row: {
        flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 15, marginTop: 15
    }

});

const MyDoc = (props) => (
    <Document>
        <Page>
            <View style={{ display: 'flex', justifyContent: 'space-evenly', margin: 70 }}>
                <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 25 }}>
                    <Text style={{ fontSize: 20, fontWeight: 'bold', fontStyle: 'normal' }}>
                        Offline comparison
                    </Text>
                </View>


                <View style={styles.row}><Text style={styles.underlined}>User</Text><Text style={styles.normalText}>: {props.user.email}</Text></View>
                <View style={styles.row}><Text style={styles.underlined}>Date</Text><Text style={styles.normalText}>: {new Date().getDate()}/{(new Date().getMonth() + 1)}/{new Date().getFullYear()}</Text><View style={{ marginLeft: 25 }} /><Text style={styles.underlined}>Time</Text><Text style={styles.normalText}>: {new Date().getHours()}:{new Date().getMinutes()}</Text></View>
                <View><Text style={styles.boldText}>Keywords with frequency:</Text></View>
                <View><Text style={styles.normalText}>{props.keywords}</Text></View>
                <View ><Text style={styles.boldText}>Text:</Text></View>
                <View><Text style={styles.normalText}>{props.text}</Text></View>
                <View style={{ marginTop: 25, marginBottom: 25 }}><Text>--------------------------------------------------------------------------</Text></View>
                <View><Text style={styles.boldText}>RESULTS</Text></View>
                <View style={styles.row}><Text style={styles.normalText}>Semantic enrichment: </Text><Text style={{ color: props.color }}>{parseInt(props.result.semanticEnrichment)}%</Text></View>



                {
                    (props.result.greenWordsByFreq.greenWordsWithRemainingFreq && Object.keys(props.result.greenWordsByFreq.greenWordsWithRemainingFreq).length > 0) &&
                    <View>
                        <View><Text style={styles.boldText && { marginBottom: 20, marginTop: 20 }}>Added keywords with remaining frequency: </Text></View>
                        <View>
                            <Text>
                                {Object.keys(props.result.greenWordsByFreq.greenWordsWithRemainingFreq).map((item, key) => {
                                    return (
                                        <Text style={styles.normalText} key={key}>{item}(
                                            <Text style={{ textDecoration: 'line-through' }}>{props.convertedText[item]},</Text>
                                            <Text>{props.result.greenWordsByFreq.greenWordsWithRemainingFreq[item]}) </Text>
                                        </Text>
                                    )
                                })}
                            </Text>
                        </View>
                    </View>
                }

                {
                    (props.result.remainingKeywords && Object.keys(props.result.remainingKeywords).length > 0) &&
                    <View>
                        <View><Text style={styles.boldText && { marginBottom: 20, marginTop: 20 }}>Remaining keywords:</Text></View>
                        <View>
                            <Text style={styles.normalText}>

                                {Object.keys(props.result.remainingKeywords).map((item, key) => {
                                    let word = item + '(' + props.result.remainingKeywords[item] + ') '
                                    return (word)
                                })}

                            </Text>
                        </View>
                    </View>
                }


            </View>

        </Page>
    </Document>
)

export default function Analyzer() {
    const classes = useStyles();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [keywords, setKeywords] = useState('');
    const [text, setText] = useState("");
    const [language, setLanguage] = useState('FRENCH');
    const [openLanguage, setOpenLanguage] = useState(false);
    const [result, setResult] = useState('')
    const toggle = () => setPopoverOpen(!popoverOpen);
    const [color, setColor] = useState('black');
    const [redirect, setRedirect] = useState(false)
    const [convertedText, setConvertedText] = useState({});
    const [showError, setShowError] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [ready, setReady] = useState(false);
    const [pdfReady, setPdfShow] = useState(false)
    const [loading, setLoading] = useState(false);
    const [pdfTitle, setPdfTitle] = useState('');

    const toggleReady = () => {
        setTimeout(() => setReady(true), 1)
    }
    const handleClose = () => setShowPopup(false);
    const style = {
        display: 'flex', justifyContent: 'center', alignItems: 'center'

    };
    const FetchResult = () => {
        if (keywords === '' || text === '') {
            setShowError(true)
        } else {
            setShowError(false)
            let list = {};
            if (user.analysis !== user.limit) {
                if (keywords[keywords.indexOf(')') + 1] === ',') {
                    const lines = keywords.split(',');
                    const firstItem = lines[0];
                    if (firstItem.length > 0) {
                        const number = parseInt(firstItem.substring(
                            firstItem.lastIndexOf("(") + 1,
                            firstItem.lastIndexOf(")")));
                        const value = firstItem.substring(0, firstItem.indexOf('('))
                        list = {
                            ...list,
                            [value.trim()]: number
                        };

                    }
                    lines.slice(1).map(item => {
                        if (item.length > 0) {
                            const number = parseInt(item.substring(
                                item.lastIndexOf("(") + 1,
                                item.lastIndexOf(")")));
                            const value = item.substring(1, item.indexOf('('))
                            list = {
                                ...list,
                                [value.trim()]: number
                            };

                        }
                    });
                } else {
                    const lines = keywords.split('\n');
                    lines.map(item => {
                        if (item.length > 0) {
                            const number = parseInt(item.substring(
                                item.lastIndexOf("(") + 1,
                                item.lastIndexOf(")")));
                            const value = item.substring(0, item.indexOf('('))
                            list = {
                                ...list,
                                [value.trim()]: number
                            };

                        }
                    });
                }
                if (list && text.length > 0) {
                    setConvertedText(list)
                    console.log(JSON.stringify(list))
                    console.log(JSON.stringify(list).replace(/\\/g, ""))
                    Axios.post('https://api.monetoring.com/offline_comparison_api/1.0/offline-comparison/analyzeKeywordsWithFreqJSON', {
                        content: text,
                        keywords: list
                    }, {
                        params: {
                            language: language
                        },
                        headers: {
                            'Authorization': 'Bearer c19b42aa-8a99-300d-8fa6-1faeaa3be506',
                            "Content-Type": "application/json",
                            "accept": "application/json;charset=UTF-8"
                        }
                    }).then(res => {
                        console.log(res)
                        if (res.status === 200 && res.data.status === "ok") {
                            setLoading(false);
                            setResult(res.data);
                            if (res.data.semanticEnrichment <= 29) {
                                setColor('#ff0000');
                            }
                            if (res.data.semanticEnrichment <= 69 && 29 < res.data.semanticEnrichment) {
                                setColor('#ff9933');
                            }
                            if (res.data.semanticEnrichment > 69) {
                                setColor('#ff9933');
                            }
                            Axios.post(data.URL + "/Api/UpdateUserLimit", user, {
                                headers: {
                                    Authorization: `Bearer ${CONSTANTS.UserSession.token}`
                                }
                            }).then(res => setUser(res.data));
                            Axios.post(data.URL + "/Api/AddLog", {
                                date: new Date(),
                                keywords: JSON.stringify(list),
                                text: text,
                                userid: user.id
                            }, {
                                headers: {
                                    Authorization: `Bearer ${CONSTANTS.UserSession.token}`
                                }
                            })
                        } else {
                            console.log(res)
                            setLoading(false)
                            setShowError(true);
                            setResult(null)
                        }
                    }).catch(e => { console.log(e); setShowError(true) });
                    console.log(result)
                }
            }
        }



    }

    useEffect(() => {
        (() => {
            console.log(CONSTANTS.UserSession)
            CONSTANTS.UserSession ? Axios.post(data.URL + "/Api/GetUser?id=" + CONSTANTS.UserSession.user.id, null, {
                headers: {
                    Authorization: `Bearer ${CONSTANTS.UserSession.token}`
                }
            }).then(res => setUser(res.data)).catch(e => e.response.status === 401 ? setRedirect(true) && console.log(e.request) : null) : setRedirect(true)

        })();
    }, [])
    if (redirect) {
        CONSTANTS.dropUser();
        return (<Redirect to={'/'} />)
    }
    else {
        if (!user) {
            return (
                <div className={classes.root}>
                    <LinearProgress />
                    <LinearProgress color="secondary" />
                </div>
            )

        } else {
            return (
                <Container>
                    {
                        showError ?
                            <Modal isOpen={showError} toggle={() => {
                                setShowError(false);
                                setLoading(false);
                                setResult(null)
                            }}>
                                <ModalHeader toggle={() => {
                                    setShowError(false);
                                    setLoading(true);
                                    setResult(null)
                                }}>Problem</ModalHeader>
                                <ModalBody>
                                    An error has occurred. Please check your input or try later. If you still cannot execute your analysis, please contact sup
                                    @pixalione.com.
                                  </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={() => {
                                        setShowError(false);
                                        setLoading(false);
                                        setResult(null)
                                    }}>Ok</Button>
                                </ModalFooter>
                            </Modal> : null
                    }



                    <Modal centred style={style}
                        isOpen={showPopup} toggle={() => {
                            setShowPopup(false);

                        }}>
                        <View style={{

                            display: 'grid',
                            justifyContent: 'space-evenly', alignItems: 'center',
                        }}>

                            <video style={{
                                marginBottom: -80,
                                high: '900px',
                                width: '700px',
                            }} controls >
                                <source src="/Images/Pixalione.mp4" type="video/mp4" />
                            </video>





                        </View>


                    </Modal>
                    <Row>

                        <Col xs={'6'} >
                            <Row>
                                <h5 style={{
                                    color: 'red'
                                }}>Please check the tutorial before start using the tool <img src="/Images/video.png" width="50" height="30" onClick={() => {
                                    setShowPopup(true);

                                }} /></h5>
                            </Row>
                            <Row>

                                <h4>Put keywords with frequency : </h4> <HelpIcon id="Popover" />
                                <Popover placement="bottom" isOpen={popoverOpen} target="Popover" toggle={toggle}>
                                    <PopoverHeader>Instruction</PopoverHeader>
                                    <PopoverBody>
                                        <Container>
                                            <Row>

                                                Desired format:
                                                -  keyword (frequency)
                                                Separate keywords by « Enter ».
                                                
                                        </Row>
                                            <Row>
                                                ---------------------------------
                                                </Row>
                                            <Row>
                                                Example:
                                                </Row>
                                            <Row>
                                                keyword1 (2)
                                                </Row>
                                            <Row>
                                                keyword2 (1)
                                                </Row>
                                            <Row>
                                                keyword3 (1)
                                                </Row>
                                            <Row>
                                                keyword4 (2)
                                                </Row>
                                        </Container>
                                    </PopoverBody>
                                </Popover>

                            </Row>

                            <TextField
                                value={keywords}
                                rowsMax="10"
                                onChange={(e) => setKeywords(e.target.value)}
                                inputProps={{
                                    style: {
                                        display: 'flex'
                                    }
                                }}
                                placeholder={'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n…'}
                                className={classes.form}
                                variant={'outlined'}
                                multiline
                                margin={'dense'}
                            />

                            <h4>Put Text :</h4>
                            <TextField
                                value={text}
                                className={classes.form}
                                multiline
                                rowsMax="10"
                                inputProps={{
                                    style: {
                                        display: 'flex'
                                    }
                                }}

                                onChange={(e) => setText(e.target.value)}
                                variant={'outlined'}
                            />
                            <Row>
                                <h4 style={{ margin: 10 }}>Language : </h4>
                                <Select
                                    labelId="language-label"
                                    id="language-select"
                                    inputProps={{
                                        style: {
                                            margin: 15
                                        }
                                    }}
                                    open={openLanguage}
                                    onClose={() => setOpenLanguage(false)}
                                    onOpen={() => setOpenLanguage(true)}
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                >
                                    <MenuItem value={'FRENCH'}>FRENCH</MenuItem>
                                    <MenuItem value={'ENGLISH'}>ENGLISH</MenuItem>
                                    <MenuItem value={'BULGARIAN'}>BULGARIAN</MenuItem>
                                    <MenuItem value={'CZECH'}>CZECH</MenuItem>
                                    <MenuItem value={'DUTCH'}>DUTCH</MenuItem>
                                    <MenuItem value={'ESTONIAN'}>ESTONIAN</MenuItem>
                                    <MenuItem value={'FINNISH'}>FINNISH</MenuItem>
                                    <MenuItem value={'GERMAN'}>GERMAN</MenuItem>
                                    <MenuItem value={'GREEK'}>GREEK</MenuItem>
                                    <MenuItem value={'ITALIAN'}>ITALIAN</MenuItem>
                                    <MenuItem value={'KOREAN'}>KOREAN</MenuItem>
                                    <MenuItem value={'POLISH'}>POLISH</MenuItem>
                                    <MenuItem value={'PORTUGUESE'}>PORTUGUESE</MenuItem>
                                    <MenuItem value={'ROMANIAN'}>ROMANIAN</MenuItem>
                                    <MenuItem value={'RUSSIAN'}>RUSSIAN</MenuItem>
                                    <MenuItem value={'SPANISH'}>SPANISH</MenuItem>
                                </Select>
                            </Row>
                            <Row>
                                <Button style={{ backgroundColor: '#ff9933', color: '#ffffff', margin: 20 }}
                                    onClick={() => {
                                        setText('');
                                        setKeywords('');
                                        setLanguage('FRENCH');
                                        setResult(null)
                                    }}
                                >Reset</Button>
                                <Button style={{ backgroundColor: (user.analysis === user.limit) ? '#A9A9A9' : '#ff9933', color: '#ffffff', margin: 20 }} disabled={user.analysis === user.limit} onClick={() => {
                                    setResult(null);
                                    setLoading(true);
                                    FetchResult();
                                }} >Analyze</Button>
                            </Row>
                            <Row><h4>Remaining analysis :</h4><h4>{user.limit - user.analysis}</h4></Row>
                        </Col>




                        <Col xs={'6'}>
                            <h3 style={{ textAlign: 'center' }}>Result</h3>
                            <div style={{
                                display: 'flex', borderRadius: 15, borderWidth: 1
                            }}>
                                {
                                    loading ? <LinearProgress className={classes.root} /> : null
                                }
                                {
                                    result ?
                                        <Col>
                                            <b>Semantic enrichment:  </b>  <h2 style={{ color: color, marginLeft: 10 }}>{parseInt(result.semanticEnrichment)}%</h2>
                                            {
                                                (result.greenWordsByFreq.greenWordsWithRemainingFreq && Object.keys(result.greenWordsByFreq.greenWordsWithRemainingFreq).length > 0) ?
                                                    <div>
                                                        <b>Added keywords with remaining frequency:  </b>
                                                        <p>
                                                            {Object.keys(result.greenWordsByFreq.greenWordsWithRemainingFreq).map((item, key) => {

                                                                return (
                                                                    <span key={key}>{item}(<span style={{ textDecorationLine: 'line-through' }}>{convertedText[item]},</span>{result.greenWordsByFreq.greenWordsWithRemainingFreq[item]})<span>  </span></span>
                                                                )
                                                            })}
                                                        </p>
                                                    </div> : null
                                            }

                                            {
                                                (result.remainingKeywords && Object.keys(result.remainingKeywords).length > 0) ?
                                                    <div>
                                                        <b>Remaining keywords: </b>
                                                        <p>
                                                            {Object.keys(result.remainingKeywords).map((item, key) => {
                                                                let word = item + '(' + result.remainingKeywords[item] + ') '
                                                                return (word)
                                                            })}
                                                        </p>
                                                    </div> : null
                                            }

                                        </Col> : null
                                }


                            </div>
                            <Modal isOpen={pdfReady} toggle={() => setPdfShow(false)}>
                                <ModalHeader toggle={() => setPdfShow(false)}>Please enter the name of the principal keyword of the analysis (to use for the name of the pdf): </ModalHeader>
                                <ModalBody>
                                    <TextField
                                        onChange={e => setPdfTitle(e.target.value)}
                                    />

                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={() => {
                                        const pdfr = pdfTitle + '-' + new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear()
                                        setPdfTitle(pdfr)
                                        setPdfShow(false)
                                    }}>Ok</Button>
                                </ModalFooter>
                            </Modal>
                            {
                                result ? ready ? <PDFDownloadLink document={<MyDoc user={user} keywords={keywords} text={text} result={result} color={color} convertedText={convertedText} />} fileName={pdfTitle + '.pdf'}>
                                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <Button style={{ backgroundColor: '#ff9933', color: '#ffffff' }} onClick={() => setReady(false)} >
                                        download pdf
                                </Button>)}
                                </PDFDownloadLink> : <Button style={{ backgroundColor: '#ff9933', color: '#ffffff' }} onClick={() => { setPdfShow(true); toggleReady() }}>Generate PDF</Button>
                                    : null

                            }

                        </Col>


                    </Row>
                </Container>
            )
        }
    }


}


const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        margin: 15
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
