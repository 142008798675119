export let UserSession = localStorage.getItem("User") ? JSON.parse(localStorage.getItem("User")) : null;

export function setUser(object) {
    localStorage.setItem("User", JSON.stringify(object)); 
    UserSession = object;
}

export function dropUser() { 
    UserSession = null;
    localStorage.removeItem("User")
};