import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import Confirmation from './components/Confirmation';
import Test from './Test';
import NotFound from './components/NotFound';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter  basename={baseUrl} >
    <App />     
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

/*
 <BrowserRouter  basename={baseUrl}>

<Route path='*'   component={({ staticContext }) => {
        
                        if (staticContext && staticContext.status ==404) {
                          return <h1>Not Found :(</h1>;
                        }
                    }} />
*/