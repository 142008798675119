import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import { AccountCircle } from '@material-ui/icons';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Button, Link,TextField } from '@material-ui/core'
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import * as CONSTANTS from './helpers/UserProfile'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
 


const data = require('./config.json')
function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [showPassword, setTypePassword] = useState(false);
    const [label, setLabel] = useState(null);
    const [user, setUser] = useState(null)
    const classes = useStyles();
    const [redirect, setRedirect] = useState(false);

    const [openPR, setOpenPR] = useState(false);
    const handleSubmit = () => {

        Axios.post(data.URL + "/Api/Login", { email: email, password: password }).then(res => {
            localStorage.setItem("User", JSON.stringify(res.data)); setUser(res.data.user); CONSTANTS.setUser(res.data); setRedirect(true);
        }).catch(e => {
            switch (e.response.status) {
                case 404: setLabel("User not found"); break;
                case 401: setLabel("This Account has not been activated"); break;
                case 400: setLabel("Please fill the form with valid information"); break;
            }

        });
    }
    if (redirect) {
        if (user.userType) {
            return (
                <Redirect to={'/Admin/Analyzer'} />
            )
        } else {
            return (
                <Redirect to={'/Freelancer'} />
            )
        }

    } else {
        return (
            <div style={{ display: 'flex', backgroundImage: `url(${data.URL + '/Images/mainbackground.jpg'})`, width: window.innerWidth, height: window.innerHeight, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', width: '30%', height: '70%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(240, 245, 245,0.6)', flexDirection: 'row', borderRadius: 25, borderColor: 'rgba(195, 213, 213,0.9)', borderWidth: 1 }}>
                    <form style={{ display: 'flex', flexDirection: 'column' }} >
                        <img src={data.URL + '/Images/logo.png'} height={"100%"} width={"100%"} />
                        <FormControl className={clsx(classes.form)} variant="outlined">
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <OutlinedInput
                                id="email"
                                type={'text'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <AccountCircle />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl >
                        <FormControl className={clsx(classes.form, classes.margin)} variant="outlined">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setTypePassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                        {
                            label ? <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {label} <strong>Please Provide valid Information</strong>
                            </Alert> : null
                        }
                        <Link href={"#"} onClick={() => setOpenPR(true)}>Forgot Password?</Link>
                        <Button className={clsx(classes.Button)} onClick={handleSubmit}>Login</Button>
                        {
                            openPR ? <ModalDelete open={openPR} handleCloseAdd={() => setOpenPR(false)} /> : null
                        }
                    </form>
                </div>

            </div>
        )
    }

}
const ModalDelete = (props) => {
    const [email, setEmail] = useState('');
    const [label,setLabel] = useState(null);
    return (
        <Modal isOpen={props.open} toggle={props.handleCloseAdd}>
            <ModalHeader toggle={props.handleCloseAdd}>Password Recovery</ModalHeader>
            <ModalBody>
                <TextField
                    label={'Email'}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                {
                    label ? <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {label} <strong>Please Provide valid Information</strong>
                    </Alert> : null
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => {

                    let emailcheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                    if (!email.match(emailcheck)){
                        setLabel("Email not valid")
                    }else{
                        Axios.post(data.URL + "/Api/SendPasswordRecovery?email=" + email)
                        .then(res => props.handleCloseAdd()).then(res => props.handleCloseAdd).catch(e => {
                            switch(e.response.state){
                                case 404 : setLabel("This Email doesn't exist") ; break;
                                case 400 : setLabel("Error Please try again") ; break;
                            }
                        })
                    }
                        
                }}>Send</Button>{' '}
                <Button color="secondary" onClick={props.handleCloseAdd}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        width: '100%'
    },
    margin: {
        marginTop: 20,
        marginBottom: 20
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
    Button: {
        backgroundColor: 'rgb(0, 102, 102)',
        color: '#ffffff',
        width: '40%',
        marginTop: 20,
        marginBottom: 20,
        left: '50%'
    }
}));

export default Login;