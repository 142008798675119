import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import './custom.css'
import { BrowserRouter, Redirect } from 'react-router-dom';
import Login from './Login'

import './custom.css'
import AdminMainMenu from './components/Admin/AdminMainMenu';
import FreelancerMainMenu from './components/Freelancer/FreelancerMainMenu'
import Confirmation from './components/Confirmation';
import Test from './Test';
import {UserSession ,dropUser} from './helpers/UserProfile'
import PasswordRecover from './components/PasswordRecover';

export default class App extends Component {
  static displayName = App.name;
  render() {
    return (
      <Switch>
        <Route exact path='/' render={()=> { 
          const User =  UserSession ? UserSession.user : null;
          if(User){
              if(User.userType === true){
                return(<Redirect to={'/Admin/Analyzer'} />)
              }else{
                return(<Redirect to={'/Freelancer'} />)
              }
          }else{
            return(<Login />)
          }
        }} />
        <Route path='/Admin' component={AdminMainMenu} />
        <Route path='/Freelancer' component={FreelancerMainMenu} />
        <Route path='/Confirmation' component={Confirmation} />
        <Route path='/ChangePassword' component={PasswordRecover} />
      </Switch>
    )

  }
}
  //  <Route path='/Freelancer' component={FreelancerMainMenu} />