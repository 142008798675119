import React, { useEffect, useState } from "react";
import Axios from 'axios';
import * as CONSTANTS from '../../helpers/UserProfile';
import { Redirect } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { MDBDataTable } from 'mdbreact';
import { CSVLink } from 'react-csv'
import {
    Card,
    CardContent,
    CardHeader, TextField
} from "@material-ui/core";



const data = require('../../config.json')
export default function Log() {
    const classes = useStyle();
    const [datatable, setData] = useState([])
    const [user, setUser] = useState(null);
    const [redirect, setRedirect] = useState(false);

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [selected ,setSelectted] = useState([]);
    const handleStartDateChange = (date) => {
        setStartDate(date.target.value);
    };
    const handleEndDateChange = (date) => {
        setEndDate(date.target.value);
    };
    useEffect(() => {
        (() => {
            CONSTANTS.UserSession ? Axios.post(data.URL + "/Api/GetUser?id=" + CONSTANTS.UserSession.user.id, null, {
                headers: {
                    Authorization: `Bearer ${CONSTANTS.UserSession.token}`
                }
            }).then(res => setUser(res.data)).catch(e => e.response.status === 401 ? setRedirect(true) : null) : setRedirect(true)

        })();
    }, []);
    useEffect(() => {
        (() => {
            const columns = [
                {
                    label: 'Date',
                    field: 'date',
                    width: 250,
                }, {
                    label: 'Keywords',
                    field: 'keywords',
                    width: 250,
                }, {
                    label: 'Text',
                    field: 'text',
                    width: 250,
                }, {
                    label: 'User Email',
                    field: 'user',
                    width: 250,
                }, {
                    label: 'Download',
                    field: 'download',
                    width: 250,
                }
            ];
            var rows = [];
            console.log("/Api/LogList?startdate=" + startDate + "&enddate=" + endDate)
            Axios.post(data.URL + "/Api/LogList?startdate=" + startDate + "&enddate=" + endDate, null, {
                headers: {
                    Authorization: `Bearer ${CONSTANTS.UserSession.token}`
                }
            }).then(res => res.data).then(response => {
                if (response && Object.values(response).length > 0) {
                    let listall = []; 
                    listall.push(["Date", "User", "Keywords", "Text"]);
                    Object.values(response).map(item => {
                        let list = []
                        list.push(["Date", "User", "Keywords", "Text"]);
                        let keywords = JSON.parse(item.keywords);
                        let keywordsWord = '';
                        Object.keys(keywords).map((value) => {
                            keywordsWord = keywordsWord + value.replace('"', '').replace('"', '') + '(' + keywords[value] + ')\n';
                        })
                        list.push([item.date, item.email, keywordsWord, item.text]);
                        listall.push([item.date, item.email, keywordsWord, item.text]);
                        rows.push({
                            date: item.date,
                            keywords: keywordsWord,
                            text: item.text,
                            user: item.email,
                            download: <CSVLink data={list} asyncOnClick={true} filename={'log-'+ new Date().getDate() +'-'+(new Date().getMonth() + 1 )+'-'+new Date().getFullYear()+'.csv'} > Download CSV </CSVLink>

                        });
                    });
                    setSelectted(listall)
                    setData({ columns, rows });
                }

            }).catch(e => (e && e.response.status === 401) ? setRedirect(true) : null);
        })();
    }, [startDate, endDate]);

    if (redirect) {
        CONSTANTS.dropUser();
        return (<Redirect to={'/'} />)
    }

    if (!user) {
        return (
            <div className={classes.root}>
                <LinearProgress />
                <LinearProgress color="secondary" />
            </div>
        )

    } else {

        return (
            <div style={{ marginTop: 140 }}>
                <Grid container justify="space-around">
                    <TextField
                        id="enddate"
                        label="Start Date"
                        type="date"
                        defaultValue={startDate}
                        value={startDate}
                        onChange={handleStartDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="startdate"
                        label="End Date"
                        type="date"
                        defaultValue={endDate}
                        value={endDate}
                        onChange={handleEndDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                </Grid>

                <Card className={classes.cardContainer}>
                    <CardHeader className={classes.cardHeader} title={"Logs"} action={<CSVLink data={selected} asyncOnClick={true} filename={'AllLogs-'+ new Date().getDate() +'-'+(new Date().getMonth() + 1 )+'-'+new Date().getFullYear()+'.xls'} > Export to CSV </CSVLink>} />
                    <CardContent>
                        <MDBDataTable hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} searchTop searchBottom={false} scrollX noBottomColumns
                            data={datatable} />
                    </CardContent>
                </Card>
            </div>
        )
    }
}

const useStyle = theme => ({
    cardContainer: {
        marginTop: '100px',
        display: 'contents'
    },
    cardHeader: {
        textDecorationColor: 'whiter',
        backgroundColor: '#96CED5',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(6, 210, 235, .3)'
    },
    AddButtom: {
        color: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
    }
})
